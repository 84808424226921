import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Поширені запитання | FAQ - Імперія-Друк
			</title>
			<meta name={"description"} content={"Ваші запитання щодо друку заслуговують на чіткі відповіді. Наш розділ поширених запитань призначений для надання стислої інформації, яка допоможе вам краще зрозуміти наші послуги.\n"} />
			<meta property={"og:title"} content={"Поширені запитання | FAQ - Імперія-Друк"} />
			<meta property={"og:description"} content={"Ваші запитання щодо друку заслуговують на чіткі відповіді. Наш розділ поширених запитань призначений для надання стислої інформації, яка допоможе вам краще зрозуміти наші послуги.\n"} />
			<meta property={"og:image"} content={"https://unitas-liss.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitas-liss.com/img/102264.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitas-liss.com/img/102264.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitas-liss.com/img/102264.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 70px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Які типи поліграфічних послуг ви пропонуєте?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Ми спеціалізуємося на широкому спектрі послуг, включаючи індивідуальний типографічний дизайн, цифровий та офсетний друк, а також екологічно чисті варіанти друку для різних продуктів, таких як візитки, плакати та брошури.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Чи можу я отримати індивідуальний дизайн для мого проекту?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Безумовно! Наша команда досвідчених дизайнерів може створити унікальний дизайн для вашого проекту, або ми можемо працювати з вашими існуючими роботами.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Скільки часу займає обробка замовлення?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Час виконання замовлення залежить від специфіки вашого замовлення та поточної завантаженості. Ми прагнемо виконувати більшість замовлень вчасно без шкоди для якості.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Чи пропонуєте ви екологічні варіанти друку?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Так, ми прагнемо до сталого розвитку і пропонуємо друк з використанням перероблених матеріалів та екологічно чистих чорнил.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							У чому різниця між цифровим та офсетним друком?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Цифровий друк ідеально підходить для невеликих, більш індивідуальних замовлень зі швидким терміном виконання. Офсетний друк підходить для більших обсягів, пропонуючи економічну ефективність і стабільність для великих партій.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Як я можу отримати пропозицію для мого проекту друку?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Щоб отримати детальну пропозицію, будь ласка, зв'яжіться з нами, надавши специфікацію вашого проекту. Наша команда надасть вам індивідуальний кошторис на основі ваших потреб.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});